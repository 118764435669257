<template>
<div>
    <b-row>
        <b-col cols="7">
            <!-- table -->
            <b-form-group label="Add Commission Set (YYYY-MM)" label-for="payment-method">
                <b-input-group>
                    <b-form-input id="card" v-mask="'####-##'" placeholder="YYYY-MM" v-model="set_add" trim/>
                    <b-input-group-append>
                        <b-button variant="outline-primary" @click="add_set()">
                            Add
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <b-table
                ref="selectableTable"
                selectable
                :select-mode="selectMode"
                :items="items"
                :fields="fields"
                responsive
                striped
                class="mb-0"
                @row-selected="onRowSelected">
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                        </template>
                        <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">Details</span>
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon"/>
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item>
                            <feather-icon icon="TrashIcon"/>
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template #cell(status_number)="data">
                    <b-badge :variant="status[1][data.value]">
                        {{ status[0][data.value] }}
                    </b-badge>
                </template>
            </b-table>
        </b-col>
        <b-col cols="5">
            <h6>Commission Set Detail</h6>
            <b-form-group label="Add Commission Bar" label-for="payment-method">
                <b-row>
                    <b-col cols="6">
                        <b-form-input id="card" v-mask="'########'" placeholder="Amount $" v-model="bar_add.amount" trim :disabled="selected_set_enable"/>
                    </b-col>
                    <b-col cols="6">

                        <b-input-group>
                            <b-form-input id="card" v-mask="'##'" placeholder="Percentage %" v-model="bar_add.percentage" trim :disabled="selected_set_enable"/>
                            <b-input-group-append>
                                <b-button variant="outline-primary" :disabled="selected_set_enable" @click="add_bar()">
                                    Add
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-form-group>
            <hr/>
            <b-list-group>
                <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="record in selected_set">
                    <font-awesome-icon icon="fa-solid fa-xmark" style="color:red" @click="delete_bar(record.id)"/>
                    <span>${{to_numeral(record.lower_bound)}}</span>
                    <b-badge variant="primary" pill class="badge-round">
                        {{record.percentage}}%
                    </b-badge>

                </b-list-group-item>
            </b-list-group>
        </b-col>
    </b-row>
</div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
var numeral = require('numeral');

import router from '@/router'
import {
  BTable, BAvatar, BCardBody, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend, BListGroup, BListGroupItem
} from 'bootstrap-vue'

export default {
  components: {
    router,
    BBadge,
    BListGroup,
    BListGroupItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    vSelect,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
  },
  data() {
    return {
      set_add: null,
      set_id: null,
      bar_add : {
        amount : null,
        percentage : null
      },
      selected_set_enable: true,
      centerOptions: [],
      packageOptions: [],
      paymentMethods:[],
      selected_set:[],
      classOptions: [],
      isAddNewPackageSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [{ key: 'effective_date', label: 'Effective Date' }, {key: 'created_at', label: 'Created at'}],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'GRANT', 2: 'DENY'
      },
      {
        1: 'light-success', 2: 'light-danger'
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  props: {
    userData : {
      type: Object
    }
  },
  watch: {
    userData: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    console.log(this.userData)
    this.get_data()
    console.log(numeral(1000).format('0,0.00'))
  },
  methods: {
    delete_bar(id) {
      this.$http.post(process.env.VUE_APP_API_BASE+`/commissions/set/`+this.set_id+`/delete/`,{ id:id })
        .then(res => {
          this.selected_set = res.data.records
          this.bar_add.amount = null
          this.bar_add.percentage = null
        })
    },
    add_set() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/commissions/`+this.userData.user_id+`/`,{effective_date:this.set_add})
        .then(res => {
          this.get_data()
        })
    },
    add_bar() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/commissions/set/`+this.set_id+`/`,{bar:this.bar_add})
        .then(res => {
          this.selected_set = res.data.records
          this.bar_add.amount = null
          this.bar_add.percentage = null
        })
    },
    to_numeral(number) {
      return numeral(number).format('0,0.00')
    },
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
        })
    },
    one() {
      this.isAddNewPackageSidebarActive = false
      this.get_data()
    },
    get_data() {
      if (this.userData.user_id) {
       this.$http.get(process.env.VUE_APP_API_BASE+`/commissions/`+this.userData.user_id+`/`)
         .then(res => {
           this.items = res.data.sets
           this.totalRows = res.data.total
         })
       }
    },
    get_commission_set(set) {
      this.set_id = set[0].id
       this.$http.get(process.env.VUE_APP_API_BASE+`/commissions/set/`+set[0].id+`/`)
         .then(res => {
           this.selected_set_enable = false
           this.selected_set = res.data.records
         })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.get_commission_set(items)
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
